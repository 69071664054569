exports.components = {
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-anti-slavery-tsx": () => import("./../../../src/pages/legals/anti-slavery.tsx" /* webpackChunkName: "component---src-pages-legals-anti-slavery-tsx" */),
  "component---src-pages-legals-privacy-policy-tsx": () => import("./../../../src/pages/legals/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legals-privacy-policy-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-studios-index-tsx": () => import("./../../../src/pages/studios/index.tsx" /* webpackChunkName: "component---src-pages-studios-index-tsx" */),
  "component---src-pages-studios-timeline-tsx": () => import("./../../../src/pages/studios/timeline.tsx" /* webpackChunkName: "component---src-pages-studios-timeline-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/newsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-studio-page-tsx": () => import("./../../../src/templates/studioPage.tsx" /* webpackChunkName: "component---src-templates-studio-page-tsx" */)
}

